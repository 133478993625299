import axios from 'axios'
import { Handlers, utils } from '../utils'
import MainService from './Main'
import env from '../config'

const Login     = async({payload}) => await axios.post(env.API_URL + '/addUser', payload)
const FetchData = async({query}) => await axios.get(env.API_URL + '/getData?'+utils.getQueryString(query))
const FilterData = async({payload}) => await axios.post(env.API_URL + '/filterData',payload)


const AuthService = {
    Login    : Handlers.Services(Login),
    FetchData: Handlers.Services(FetchData),
    FilterData: Handlers.Services(FilterData),

}

export default AuthService
