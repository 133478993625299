import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { LayoutContext } from "../context/layout.context";
import { useAuth } from "../outseta/AuthProvider";

const Navbar = () => {
  const [active, setActive] = useState(false);
  const layout = useContext(LayoutContext);

  const handleClick = () => {
    setActive(!active);
  };

  const {
    user,
    logout,
    openLogin,
    openSignup,
    openProfile,
    isLoading
  } = useAuth();


  const menuItems = [
    {
      title: "Home",
      url: "https://waino.io",
      cName: "nav-links",
      show : true,
    },
    {
      title: "Waino Explorer",
      url: "https://explorer.waino.io",
      cName: "nav-links",
      show : user,
    },
    {
      title: "Login",
      url: "https://waino.outseta.com/auth?widgetMode=login#o-anonymous",
      cName: "nav-links",
      show : !user,
    },
    {
        title: "Signup",
        url: "https://waino.outseta.com/auth?widgetMode=register#o-anonymous",
        cName: "nav-links",
        show : !user,
    },
    {
      title: "Profile",
      url: "https://waino.outseta.com/profile#o-authenticated",
      cName: "nav-links",
      show : user,
  },
  {
    title: "Logout",
    url: "",
    cName: "nav-links",
    show : user,
    onClick: logout
}
  
  ];
console.log('user ', user);
  return (
    <nav className="navbar" style={{background : layout?.state?.isHome ? '#000000' : '#F6F8FA'}}>
      <h1 className="navbar-logo d-flex align-items-end" style={{color : layout?.state?.isHome ? '#ffffff' : '#000000 '}}>
        Waino <div className="fs-22 ml_12 pb_4 biggerScreens">Buy better wines</div>
        <div className="fs-20 ml_12 pb_4 smallerScreens">Buy better wines</div>
      </h1>
      <div className="menu-icon" onClick={handleClick}>
        <i className={active ? "fas fa-times" : "fas fa-bars"}></i>
      </div>
      <ul className={active ? "nav-menu active" : "nav-menu"} style={{color : layout?.state?.isHome ? '#ffffff' : '#000000', background : layout?.state?.isHome ? '#000000' : '#F5F7F8'}}>
        {menuItems.map((item, index) => {
          return (
            item.show && <li key={index}>
              <a href={item?.url && item.url} onClick={item.onClick && logout} className={item.cName} style={{color : layout?.state?.isHome ? '#ffffff' : '#000000'}}>
                {item.title}
              </a>
            </li>
          );
          // adsad
        })}
      </ul>
    </nav>
  );
};

export default Navbar;
