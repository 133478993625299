import { Link } from "react-router-dom/dist";
import CustomButton from "../components/CustomButton";
import { useAuth } from "./AuthProvider";
import { CircularProgress } from "@mui/material";

const PLANS = {
  FREE: { label: "Free Plan", uid: "y9qzZ0WA" },
  BASIC: { label: "Unlock discounts & new wines plan", uid: "wQXw5ZmK" }
};

function hasCorrectPlan(user) {
  if (user && user?.Account?.CurrentSubscription?.Plan?.Uid) {
    const userPlanUid = user.Account.CurrentSubscription.Plan.Uid;
    console.log('userPlanUid ', userPlanUid);
    return userPlanUid === PLANS.BASIC.uid;
  } else {
    return false;
  }
}

export default function ProtectedRoute({ pro, children, admin }) {
  const { user, openLogin, openSignup, openProfile, isLoading } = useAuth();
  const proPlan = hasCorrectPlan(user);

  console.log('pro plan ', proPlan);

  if (isLoading) return <div className="absoluteMiddle"><CircularProgress  /></div>;
  if (!user){
    return (
      <div className="absoluteMiddle">
        <div className="text-center">
            <div className="Body32B">Click below to continue</div>
            <Link to={"https://waino.outseta.com/auth?widgetMode=login#o-anonymous"}>
            <CustomButton 
                btntext={"Login"}
                className={"mt_8"}
            ></CustomButton>
            </Link>
        </div>
      </div>
    );
  }
  else{
  if(admin){
    console.log('if ')
    if(user?.Admin?.toLowerCase() == "true"){
      return children
    }
    else{
      return (
        <div className="absoluteMiddle">
        <div className="text-center">
            <div className="Body32B" style={{color: 'red'}}>Unauthorized Access!</div>
            </div>
            </div>
      )
    }
  }
  else{
    // if(pro && !proPlan){
    //   console.log('1');
    //   return(
    //     <div className="absoluteMiddle">
    //     <div className="text-center">
    //         <div className="Body32B">You need to upgrade your plan to continue exploring discounted wines.</div>
    //         <CustomButton 
    //             btntext={"Upgrade"}
    //             onClick={() => openProfile({ tab: "planChange" })}
    //             className={"mt_8"}
    //         ></CustomButton>
    //     </div>
    //   </div>
    //   )
    // }
    if((pro && !proPlan)||(!pro && !proPlan) || (!pro && proPlan)|| (proPlan && pro)){
      return children
    }
    else {
      console.log('3');
      return (
        <div className="absoluteMiddle">
          <div className="text-center">
              <div className="Body32B">Click below to continue</div>
              <Link to={"https://waino.outseta.com/auth?widgetMode=login#o-anonymous"}>
              <CustomButton 
                  btntext={"Login"}
                  className={"mt_8"}
              ></CustomButton>
              </Link>
          </div>
        </div>
      );
    }
  }
}
}