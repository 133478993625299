import { useEffect } from "react";
import { Link, Route, Routes, Outlet, BrowserRouter } from "react-router-dom";
import AuthProvider from "./outseta/AuthProvider";
import Layout from "./outseta/Layout";
import ProtectedRoute from "./outseta/ProtectedRoute";

import Admin from './pages/admin/Admin';
import DiscountWainoExplorer from './pages/home/DiscountWainoExplorer';
import WainoExplorer from './pages/home/WainoExplorer';
import Navbar from './components/Navbar';
import CustomToasters from "./components/CustomToasters";

import { withToaster } from './context/Toaster.context';

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-QEBDGC4D19"; // your Measurement ID

function App() {

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/", title: "Explorer Page" });
}, [])

  return (
    <BrowserRouter>
      <div className="App">
      <CustomToasters/>
      <AuthProvider>
      <Navbar/>
        <Routes>
              <Route
                path="/"
                exact
                element={
                  <ProtectedRoute pro={false}>
                  <DiscountWainoExplorer/>
                  </ProtectedRoute>
                }
              />

          <Route
                path="admin"
                exact
                element={
                  <ProtectedRoute admin={true}>
                    <Admin/>
                  </ProtectedRoute>
                }
              />
          
          <Route
                path="*"
                element={
                  <>
                    <h2>Not found</h2>
                    <p>There's nothing here!</p>
                  </>
                }
              />
        </Routes>
      </AuthProvider>
      </div>
    </BrowserRouter>
  );
}

export default withToaster(App);